<template>
    <div>
      <v-container class="payment-method-container pb-4">
        <v-row>
          <v-col><strong class="text-capitalize">EFTPOS</strong></v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col class="ma-0 pa-0"><strong>Transaction Details</strong></v-col>
        </v-row>
        <v-row class="pa-0 ma-0" v-if="payment_type && payment_type.toLowerCase() === 'eftpos'">
          <v-col class="pa-0 ma-0" :cols="(paymentData.amountTendered - paymentData.totalNewPaymentPaying > 0) ? 9 : 12">
            <small class="small-label" :class="{'text-error' : errors.first('amountTendered') }">Transaction Amount*</small>
            <v-text-field
            key="amountTendered"
            class="cash-input-filed"
            :class="{'custom-field-error' : errors.first('amountTendered') }"
              hide-details
              :value="getPrecisiedVal"
              v-validate="`required|min_value:${paymentData.totalNewPaymentPaying}`"
              data-vv-name="amountTendered"
              data-vv-as="Cash Amount Tendered"
              :error-messages="errors.collect('amountTendered')"
              prepend-inner-icon="mdi-currency-usd"
              id="amountTendered"
              name="amountTendered"
              @input="handleAmountTendered('amountTendered', $event)"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" >
          <v-col class="pa-0 ma-0 pt-2">
            <v-text-field
              key="transaction_id"
              :class="{'custom-field-error' : errors.first('transaction_id')}"
              hide-details
              :value="payment_method.transaction_id"
              v-validate="'max:45'"
              data-vv-name="transaction_id"
              data-vv-as="Transaction Id"
              :error-messages="errors.collect('transaction_id')"
              label="Transaction ID"
              id="transaction_id"
              name="transaction_id"
              @input="handleInputUpdate('payment', 'transaction_id', $event)"
              >
            </v-text-field>
            <small class="small-label" >(Mentioned as TID in the Receipt)</small>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" >
          <v-col class="pa-0 ma-0 pt-2">
            <v-select
              key="card_type"
              :items="['Visa','Mastercard','JCB','AMEX']"
              hide-details
              :value="payment_method.card_type"
              :class="{'custom-field-error' : errors.first('card_type')}"
              data-vv-name="card_type"
              data-vv-as="Card Type"
              :error-messages="errors.collect('card_type')"
              label="Card Type"
              id="card_type"
              name="card_type"
              @input="handleInputUpdate('payment_method', 'card_type', $event)"
              >
            </v-select>
          </v-col>
        </v-row>
  
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0 pt-2">
            <v-textarea
              :class="{'custom-field-error' : errors.first('notes')}"
              rows="2"
              hide-details
              :value="payment.notes"
              data-vv-name="notes"
              :error-messages="errors.collect('notes')"
              label="Notes"
              id="cash-notes"
              name="cash-notes"
              autocomplete="cc-cash-notes"
              @input="handleInputUpdate('payment', 'notes', $event)"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  
  <script type="text/babel">
  
      import PaymentMethodBase from './PaymentMethodBase.vue';
      import HbDatePicker from '../../assets/HummingbirdDatepicker';
      import VuetifyStatepicker from '../../includes/VuetifyStatepicker.vue';
      import { EventBus } from '../../../EventBus';
      import { mapGetters, mapMutations } from "vuex";
  
      export default {
          extends: PaymentMethodBase,
          data() {
              return {
              }
          },
          components:{
            HbDatePicker,
            VuetifyStatepicker
          },
          computed:{
            ...mapGetters({
              paymentConfig: 'paymentsStore/getPaymentSourceConfig',
              paymentData: 'paymentsStore/getPaymentInformation',
              payment_method: 'paymentsStore/getPaymentMethod',
              contact: 'paymentsStore/getContact',
              payment: 'paymentsStore/getPayment',
              getErrors: 'paymentsStore/getErrors'
          }),
            getPrecisiedVal() {
              return this.paymentData.amountTendered ? parseFloat(this.paymentData.amountTendered).toFixed(2) : this.paymentData.amountTendered;
            }
          },
          created(){
          },
          destroyed() {
          },
          props: ['payment_type'],
          methods:{
            ...mapMutations({
              setCheckErrors: 'paymentsStore/setCheckErrors',
              setPayment: 'paymentsStore/setPayment',
              setPaymentInformation: 'paymentsStore/setPaymentInformation',
              setPayment: 'paymentsStore/setPayment',
              setPaymentObject: 'paymentsStore/setPaymentObject',
              setPaymentMethod: 'paymentsStore/setPaymentMethod',
              setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
            }),
  
            handleAmountTendered : _.debounce(async function(property_name, property_value) {
              property_value = Number(parseFloat(property_value).toFixed(2)) || 0;
              this.setPaymentInformation({property: property_name, propertyValue: property_value});
            }, 1000),
  
            handleUpdate(obj, property_name, property_value){
        if(obj === 'payment'){
          this.setPayment({property: property_name, propertyValue: property_value});
        }
        if(obj === 'payment_method'){
          this.setPaymentMethod({property: property_name, propertyValue: property_value});
        }
      },
        handleInputUpdate : _.debounce(async function(obj, property_name, property_value) {
          this.handleUpdate(obj, property_name, property_value);
        }, 200),
  
            validateFields(){
              return this.$validator.validateAll();
            }
          },
          watch:{
          }
  
  
      }
  </script>
  <style scoped>
  
  .text-error {
    color: red !important;
  }
  
  .small-label {
    font-size: 11px; 
    color: grey;
  }
  
  .cash-input-filed {
    padding-top: 0px;
    margin-top: -5px;;
  }
  
  </style>
  