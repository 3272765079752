<!-- WARNING!!!! ****** IF YOU WANT TO USE THIS COMPONENT PASS FULL CONTACT DETAILS OR MAKE USE CONTACT FALSE ****** WARNING!!!!-->
<template>
  <div v-if="show_skeleton && tenantProfile">
    <v-skeleton-loader
      class="mx-auto"
      type="list-item-avatar-two-line"
    ></v-skeleton-loader>
  </div>
  <div v-else>
    <hb-mini-profile
      :type=" contact?.isNational ? 'account' : contact?.rent_as_business ? 'business' : 'contact'"
      :title="contact?.first + ' ' + contactMiddleName + ' ' + contact?.last"
      :representativeName="contact?.rent_as_business ? computedRepresentativeName(contact) : ''"
      :status="contact?.status"
      :military="contact?.Military?.active === 1"
      :phone="formatPhoneNumber(contact?.Phones[0]?.phone)"
      :can-text="contact?.Phones?.length > 0 ? !!contact?.Phones[0]?.sms : ''"
      :email="contact?.email"
      :address="contactAddressInfo(contact)"
      :past-due-amount="contact.Leases && contact.Leases?.length > 0 ? calPastDueAmount(contact?.Leases) : ''"
      :is-lead="calTotalLeads(contact) === 1 && contact?.Leases?.length === 0 ? true : false"
      :lead-type="contact.Leads && contact.Leads?.length > 0 ? leadInfo(contact).type : ''"
      :lead-space-type="leadInfo(contact).spaceType"
      :lead-space-number="leadInfo(contact).spaceNumber"
      :lead-space-category="leadInfo(contact).spaceLabel"
      :lead-featured-amenity="leadInfo(contact).spaceCategory"
      :lead-property-info="leadInfo(contact).spaceAddress || ''"
      :use-menu="calTotalLeasesLength(contact) > 1 ? true : false"
      :contact-active-lead-count="calTotalLeads(contact)"
      :contact-space-type="
        contact.Leases && contact.Leases?.length === 1 && contact?.Leases[0]?.Unit?.unit_type_id
          ? getUnitTypeTitle(contact?.Leases[0]?.Unit)
          : 'default'
      "
      :contact-space-number="
        contact?.Leases && contact?.Leases?.length === 1
          ? contact?.Leases[0]?.Unit?.number
          : ''
      "
      :contact-space-category="
        contact?.Leases && contact?.Leases?.length === 1
          ? contact?.Leases[0]?.Unit?.label
          : ''
      "
      :contact-space-property-info="
        contact.Leases && contact.Leases?.length === 1
          ? contact.Leases[0]?.Unit?.property_number + ' - ' + spaceAddressInfo(contact.Leases[0]?.Unit?.Address)
          : ''
      "
      disable-contact-space-link
      disable-lead-link
      :disable-contact-space-info="calTotalLeasesLength(contact) === 0 ? true : false"
      :contact-space-access-code="accessAPIDone ? spaceAccessCode(contact?.Leases[0]?.Unit?.property_id, contact?.Leases[0]?.Unit?.id)?.pin : ''"
      :contact-space-lockout="contact.Leases && contact.Leases?.length === 1 && (spaceAccessCode(contact?.Leases[0]?.Unit?.property_id, contact?.Leases[0]?.Unit?.id)?.hard_catch === 1 || spaceAccessCode(contact?.Leases[0]?.Unit?.property_id, contact?.Leases[0]?.Unit?.id)?.late_catch === 1 || spaceAccessCode(contact?.Leases[0]?.Unit?.property_id, contact?.Leases[0]?.Unit?.id)?.status === 0) ? true : false"
      :contact-space-count="calculateLength(contact.Leases, 'sameProperties')"
      :contact-space-count-other-properties="
        calculateLength(contact.Leases, 'otherProperties') ?? 0
      "
    >
      <template
        v-slot:reportTooltipBody
        v-if="contact?.Leases && contact?.Leases?.length > 0"
      >
        Total Balance: {{ calTotalBalance(contact?.Leases) }}<br />
        Lifetime Value: {{ calLifetimePayment(contact?.Leases) | formatMoney }}<br />
        Prepaid Balance: {{ totalPrepaidBalance(contact?.Leases) | formatMoney
        }}<br />
      </template>
      <template
        v-if="calculateLength(contact.Leases, 'sameProperties') > 0"
        v-slot:menuSpaces
      >
        <div v-for="lease in contact.Leases">
          <HbMiniProfileItem
            v-if="filterLeases(lease.Unit?.property_id) && !lease.end_date"
            type="space"
            :space-type="lease.Unit?.type"
            :space-category="lease.Unit?.label"
            :space-number="lease.Unit?.number"
            :space-property-info="lease.Unit?.property_number + ' - ' + spaceAddressInfo(lease.Unit?.Address)"
            :space-status="lease?.Standing?.name"
            :space-lockout="(spaceAccessCode(lease?.Unit?.property_id, lease?.Unit?.id)?.hard_catch === 1 || spaceAccessCode(lease?.Unit?.property_id, lease?.Unit?.id)?.late_catch === 1 || spaceAccessCode(lease?.Unit?.property_id, lease?.Unit?.id)?.status === 0) ? true : false"
            :space-access-code="
              accessAPIDone ? spaceAccessCode(lease.Unit?.property_id, lease.Unit?.id)?.pin : ''
            "
            disable-space-link
          />
        </div>
      </template>
      <template
        v-if="calculateLength(contact.Leases, 'otherProperties') > 0"
        v-slot:menuSpacesOtherProperties
      >
        <div v-for="lease in contact.Leases">
          <HbMiniProfileItem
            v-if="filterLeases(lease.Unit?.property_id) === false && !lease.end_date"
            type="space"
            :space-type="lease.Unit?.type"
            :space-number="lease.Unit?.number"
            :space-category="lease.Unit?.label"
            :space-status="lease.Standing?.name"
            :space-property-info="lease.Unit?.property_number + ' - ' + spaceAddressInfo(lease.Unit?.Address)"
            disable-space-link
          />
        </div>
      </template>
      <template v-if="calTotalLeads(contact) > 0" v-slot:menuActiveLeads>
        <div v-if="contact.Reservations?.length > 0">
          <div v-for="reservation in contact.Reservations">
            <HbMiniProfileItem
              type="lead"
              :lead-type="reservationInfo(reservation)?.type"
              :lead-space-type="reservationInfo(reservation).spaceType"
              :lead-space-number="reservationInfo(reservation).spaceNumber"
              :lead-space-category="reservationInfo(reservation).spaceLabel"
              :lead-property-info="reservationInfo(reservation).spaceAddress"
              disable-lead-link
            />
          </div>
        </div>
        <div v-if="contact.Pending?.length > 0">
          <div v-for="pending in contact.Pending">
            <HbMiniProfileItem
              type="lead"
              :lead-type="pendingInfo(pending)?.type"
              :lead-space-type="pendingInfo(pending).spaceType"
              :lead-space-number="pendingInfo(pending).spaceNumber"
              :lead-space-category="pendingInfo(pending).spaceLabel"
              :lead-property-info="pendingInfo(pending).spaceAddress"
              disable-lead-link
            />
          </div>
        </div>
        <div v-for="lead in contact.Leads">
          <div
          v-if="
            lead.id &&
            !checkForExistsLead(contact.Reservations, lead.Reservation?.id)&&
            !checkForExistsLead(contact.Leases, lead.Lease?.id)&&
            !checkForExistsLead(contact.Pending, lead.Lease?.id)
          "
          >
          <HbMiniProfileItem
            type="lead"
            :lead-type="multiLeadInfo(lead)?.type"
            :lead-space-type="multiLeadInfo(lead).spaceType"
            :lead-space-number="multiLeadInfo(lead).spaceNumber"
            :lead-space-category="multiLeadInfo(lead).spaceLabel"
            :lead-property-info="multiLeadInfo(lead).spaceAddress"
            disable-lead-link
          />
        </div>
        </div>
      </template>
      <template v-if="contact?.closedLeases?.length > 0" v-slot:menuClosedLeases>
        <div v-for="closedLease in contact?.closedLeases">
          <HbMiniProfileItem
            type="closed"
            :closed-space-type="closedLease.type || 'storage'"
            :closed-count="closedLease.count"
          />
        </div>
        </template>
    </hb-mini-profile>
  </div>
</template>
<script type="text/babel">
import moment from "moment";
import api from "../../../assets/api.js";
import { parsePhoneNumber } from "libphonenumber-js";
import { mapGetters } from "vuex";
import contacts from "../../../assets/api/contacts.js";
import { EventBus } from "../../../EventBus.js";
export default {
  name: "MiniProfileView",
  data() {
    return {
      show_skeleton:true,
      contact: {},
      access: [],
      spaceAccess: [],
      accessAPIDone: false,
    };
  },
  props: {
    contactData:{
      type:Object
    }, 
    contactId:{
      type:String
    }, 
    useContact:{
      type: Boolean,
    }, 
    disableSpaceAccess:{
      type: Boolean,
    },
    tenantProfile:{
      type: Boolean,
      default: false
    },
    showMiddleName:{
      type: Boolean,
      default: false
    }
  },
  async created() {
    if (this.useContact === true) {
      this.contact = this.contactData;
      let newLease = this.sortLeasesByStanding(this.contact.Leases)
      this.contact.Leases = newLease
      if (!this.disableSpaceAccess) {
        await this.getSpaceAccessCode(this.contactId);
      }
    } else {
      this.getContactDetails(this.contactId);
    }
    EventBus.$on('access_pin_modified', () => {
      this.getSpaceAccessCode(this.contactId);
    })
  },
  destroyed(){
    EventBus.$off('access_pin_modified');
  },
  computed: {
    ...mapGetters({
      selectedProperties: "propertiesStore/filtered",
    }),
    contactMiddleName() {
      if(this.showMiddleName && this.contact?.middle != null){
        return this.contact?.middle;
      } else {
        return '';
      }
    }
  },
  methods: {
    async getContactDetails(contactId) {
      let r = await api.get(this.$app, api.CONTACTS + contactId + '/get-contacts-details-by-contactId');
      let newLease = this.sortLeasesByStanding(r.contact.Leases)
      r.contact.Leases = newLease;
      this.contact = r.contact;
      await this.getSpaceAccessCode(contactId);
    },
    formatPhoneNumber(value) {
      if (!value) return "";
      try {
        var parsedPhoneNumber = parsePhoneNumber("+" + value);
        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
          if (parsedPhoneNumber.country === "US") {
            return "+1 " + parsedPhoneNumber.formatNational();
          } else {
            return parsedPhoneNumber.formatInternational();
          }
        } else {
          value = value.toString();

          var numbers = value.replace(/\D/g, ""),
            char = { 0: "(", 3: ") ", 6: "-" };
          value = "";
          for (var i = 0; i < numbers.length; i++) {
            value += (char[i] || "") + numbers[i];
          }
        }
      } catch (err) {}
      return value;
    },
    calTotalBalance(lease) {
      let total = 0;
      for (let i = 0; i < lease?.length; i++) {
        total += lease[i].open_balance;
      }
      return this.$options.filters.formatMoney(total);
    },
    computedRepresentativeName(contact) {
      if (contact.Representative) {
        return (
          (contact?.Representative?.Contact?.first
            ? contact?.Representative?.Contact?.first
            : "") +
          (contact?.Representative?.Contact?.last
            ? " " + contact?.Representative?.Contact?.last
            : "")
        );
      }
      return undefined;
    },
    contactAddressInfo(contact) {
      if (
        contact?.Addresses &&
        contact?.Addresses.length > 0 &&
        contact?.Addresses[0]?.Address
      ) {
        const address_1 = contact.Addresses[0]?.Address?.address
          ? contact.Addresses[0]?.Address?.address
          : "";
        const address_2 = contact.Addresses[0]?.Address?.address2
          ? contact.Addresses[0]?.Address?.address2
          : "";

        let address = address_1;
        if (address_2 != "") address += " " + address_2;

        address +=
          ", " +
          this.$options.filters.formatAddress(contact.Addresses[0]?.Address);

        return address;
      } else {
        return undefined;
      }
    },
    spaceAddressInfo(spaceAddress) {
      return this.$options.filters.formatAddress(spaceAddress, "^", true);
    },
    leadInfo(contact) {
      if(Object.keys(contact).length > 0){
        if (contact.Leads?.length > 0 && contact?.Leads[0]?.Reservation?.id && contact?.Reservations.length === 1) {
          return {
            type: "Reservation",
            spaceType: contact.Reservations[0]?.Lease?.Unit.type,
            spaceNumber: contact.Reservations[0]?.Lease?.Unit.number,
            spaceLabel: contact.Reservations[0]?.Lease?.Unit.label,
            spaceAddress: contact.Reservations[0]?.Lease?.Unit?.property_number + ' - ' + this.spaceAddressInfo(
              contact.Reservations[0]?.Lease?.Unit.Address
            ),
          };
        }else if(contact?.Pending && contact?.Pending.length === 1){
          return {
            type: "Pending",
            spaceType: contact.Pending[0]?.Unit?.type,
            spaceNumber: contact.Pending[0]?.Unit?.number,
            spaceLabel: contact.Pending[0]?.Unit?.label,
            spaceAddress: contact.Pending[0]?.Unit?.property_number + ' - ' + this.spaceAddressInfo(
              contact.Pending[0]?.Unit?.Address
            ),
          };
        } else {
          return {
            type: contact.Leads && contact.Leads.length > 0 && contact.Leads[0]?.lead_type ? contact.Leads[0]?.lead_type : "Lead",
            spaceType: contact.Leads[0]?.Unit?.type || "",
            spaceNumber: contact?.Leads[0]?.Unit?.number || "",
            spaceCategory: contact?.Leads[0]?.Category?.name || "",
            spaceLabel: contact?.Leads[0]?.Unit?.label || "",
            spaceAddress: contact?.Leads[0]?.Property ? contact?.Leads[0]?.Property?.number + ' - ' + this.spaceAddressInfo(
              contact?.Leads[0]?.Property?.Address) : '',
          };
        }
      }
    },
    reservationInfo(reservation) {
      return {
        type: "Reservation",
        spaceType: reservation?.Lease?.Unit?.type,
        spaceNumber: reservation?.Lease?.Unit?.number,
        spaceLabel: reservation?.Lease?.Unit?.label,
        spaceAddress: reservation?.Lease?.Unit?.property_number + ' - ' + this.spaceAddressInfo(reservation?.Lease?.Unit?.Address),
      };
    },
    pendingInfo(pending) {
      return {
        type: "Pending",
        spaceType: pending?.Unit?.type,
        spaceNumber: pending?.Unit?.number,
        spaceLabel: pending?.Unit?.label,
        spaceAddress: pending?.Unit?.property_number + ' - ' + this.spaceAddressInfo(pending?.Unit?.Address),
      };
    },
    multiLeadInfo(lead) {
      return {
        type: lead?.lead_type ?? "Lead",
        spaceType: lead?.Unit?.type,
        spaceNumber: lead?.Unit?.number,
        spaceLabel: lead?.Category?.name,
        spaceAddress: lead?.Property?.number + ' - ' + this.spaceAddressInfo(lead?.Property?.Address),
      };
    },
    totalPrepaidBalance(leases) {
      if (leases?.length > 0) {
        return leases
          .filter(
            (l) =>
              l.end_date === null ||
              moment(l.end_date).startOf("day") > moment().startOf("day")
          )
          .reduce(
            (a, l) =>
              a +
              (l.Metrics && l.Metrics.prepaid_balance
                ? Math.round(l.Metrics.prepaid_balance * 1e2) / 1e2
                : 0),
            0
          );
      } else {
        return null;
      }
    },
    calTotalLeasesLength(contact) {
      let leases = contact.Leases?.filter(lease => lease.end_date == null)
      let closeLeases = contact?.closedLeases ? contact?.closedLeases?.reduce((acc,item)=>acc + item.count, 0) : 0
      let leads = this.calTotalLeads(contact);
      if((leases.length + leads + closeLeases === 1) && closeLeases === 1){
        return 0;
      }else{
        return leases.length + leads + closeLeases;
      }
    },
    spaceAccessCode(property_id, unit_id) {
      if(this.access.length > 0){
        let accessPin = this.access.filter(a => a.property_id == property_id)
        if(accessPin[0]?.name === 'Derrels'){
          let spaceAccessPin = this.spaceAccess.filter(a => a.unit_id == unit_id)
          return spaceAccessPin[0] || ''
        }else {
          return accessPin[0];
        }
      }
      return '';
    },
    filterLeases(property_id) {
      return this.selectedProperties.some((i) => i.id === property_id);
    },
    calPastDueAmount(leases) {
      if(leases.length > 0){
        let currentLeases = leases?.filter((lease) => {
          return this.filterLeases(lease.Unit.property_id);
        })
        let totalBalance = this.calTotalBalance(currentLeases);
        if(totalBalance == '$0.00'){
          return '';
        }else{
          return totalBalance + '';
        }
      }

      return 0;
    },
    calculateLength(leases, check) {
      if(leases){
        let activeLeases = leases.filter(lease => !lease.end_date)
        if (check === "sameProperties") {
          return activeLeases?.filter((lease) => {
            return this.filterLeases(lease.Unit.property_id);
          })?.length;
        } else{
          return (
            activeLeases?.length -
            activeLeases?.filter((lease) => {
              return this.filterLeases(lease.Unit.property_id);
            })?.length
          );
        }
      }
      return 0;
    },
    calTotalLeads(contact) {
      if(Object.keys(contact).length > 0){
        let reservations = contact.Reservations
          ? contact.Reservations?.length
          : 0;
        let pending = contact.Pending ? contact.Pending?.length : 0;
        let leads = 0;
        for (let index = 0; index < contact.Leads.length; index++) {
          if(!contact.Leads[index]?.Lease?.id){
            if(!this.checkForExistsLead(contact.Reservations, contact.Leads[index].Reservation?.id) && 
            !this.checkForExistsLead(contact.Leases, contact.Leads[index].Lease?.id) &&
            !this.checkForExistsLead(contact.Pending, contact.Leads[index].Lease?.id)){
              leads = leads + 1
            }else if(!contact.Leads[index].Reservation?.id){
              leads = leads + 1;
            }
          }
        }

        return reservations + pending + leads;
      }
      return 0;
    },
    checkForExistsLead(reservations, reservation_id){
      if(reservations.length > 0){
        return reservations.some((i) => i?.id === reservation_id);
      }
    },
    calLifetimePayment(leases){
      return leases.reduce((acc, l) => acc + l.Metrics?.lifetime_payments, 0);
    },
    sortLeasesByStanding(leases) {
      const standingOrder = {
              "Auction": 0,
              "Active Lien": 1,
              "Delinquent": 2,
              "Current": 3,
              "Balance Due": 4,  
              "Gate Lockout":	5,
              "Suspended": 6,
              "Lease Closed": 7,
              "Pending": 8,
          };
      const sortedLeases = leases.sort((a, b) => standingOrder[a?.Standing?.name] - standingOrder[b?.Standing?.name]);
      return sortedLeases;
    },
    async getSpaceAccessCode(contact_id) {
      try {
        this.accessAPIDone = false;
        let r = await api.get(this, api.CONTACTS + contact_id + '/access')
        this.access = r.access;
        for (let index = 0; index < this.access.length; index++) {
          if(this.access[index]?.name === 'Derrels'){
            let res = await api.get(this, api.CONTACTS + contact_id + '/space-access')
            this.spaceAccess = res.access
          }
        }
        this.accessAPIDone = true;
      } catch (error) {
        console.log(error);
      }
      
    },
  },
  watch: {
    async contactId() {
      if (this.useContact === true) {
        this.contact = this.contactData;
        let newLease = this.sortLeasesByStanding(this.contact.Leases)
        await this.getSpaceAccessCode(this.contactId);
        this.contact.Leases = newLease
      } else {
        this.getContactDetails(this.contactId);
      }
    },
    contactData: {
      async handler() {
        if(this.useContact === false) {
          await this.getContactDetails(this.contactId);
        }
      },  
      deep: true
    },
    contact(val){
      this.contact = val;
      this.show_skeleton=false;
    }
  },
};
</script>
