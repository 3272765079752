<template>
  <hb-modal
    size="medium"
    :title="title"
    v-model="dialog"
    confirmation
    show-help-link
  >
    <template v-slot:content>
      <div class="py-4 px-6">
        <div v-if="reversalType === REVERSALS.card.CHARGEBACK.value">
          The payment will be reversed in the tenant’s ledger, but no money will
          be returned to the tenant’s credit card and Hummingbird will process
          any applicable delinquency stages in the next 10 minutes.
          <br /><br />
          If you want to return money to the tenant’s credit card, click cancel
          and choose the refund or void option.
          <br /><br />
        </div>

        <div v-if="reversalType === REVERSALS.card.OFFLINE.value">
          The payment will be reversed in the tenant’s ledger but NO MONEY WILL
          BE RETURNED to the tenant’s credit card. The payment was directly
          refunded from your payment processor. Applicable delinquency stages
          will process in the next 10 minutues. 
					<br /><br />
					To proceed, click the complete
          payment reversal. To return money to the tenant’s credit card, click
          cancel and choose the Refund or Void option.
					<br /><br />
        </div>

        <div v-if="reversalType === REVERSALS.check.NSF.value">
          You are about to reverse this payment and Hummingbird will process any
          applicable delinquency stages in the next 10 minutes.
					<br /><br />
        </div>

        <div v-if="reversalType === REVERSALS.ach.ACH.value">
          The payment will be reversed in the tenant’s ledger, but no money will
          be returned to the tenant’s credit card and Hummingbird will process
          any applicable delinquency stages in the next 10 minutes.
          <br /><br />
        </div>

        <div v-if="reversalType === REVERSALS.credit.REVERSE.value">
          You are reversing credits in the amount of <span class="font-weight-medium"> ${{ reversal_meta.amount }}</span> from :
          <br/>
          <span><span class="font-weight-medium">Space {{ spacesList.filter(getUniqueItems).join(" and ") }} </span></span>
          <br/>
          <span v-for="invNum in invoiceNums" :key="invNum">{{ invNum }} <br /></span>
          <br />
        </div>
        <div v-if="payment.is_inter_property_payment && [REVERSALS.check.NSF.value, REVERSALS.check.VOID.value, REVERSALS.ach.ACH.value].includes(reversalType)">
          This payment will be reversed from all <span class="font-weight-medium">units ({{ spacesList.filter(getUniqueItems).join(", ") }})</span> where this payment was applied.
          <br /><br />
        </div>

        Are you sure you want to {{ isRefund ? "refund" : "reverse" }} the {{ reversalType != 'credit' ? "payment" : "credit" }}? 
      </div>
    </template>
    <template v-slot:actions>
      <hb-btn v-if="isRefund" color="primary" :disabled="disableReversal" @click="processReversal">Complete {{ reversalType != 'credit' ? "Payment Refund" : "Reverse Credit" }}</hb-btn>
      <hb-btn v-else color="primary" :disabled="disableReversal" @click="processReversal">Complete {{ reversalType != 'credit' ? "Payment Reversal" : "Reverse Credit" }}</hb-btn>
    </template>
  </hb-modal>
</template>

<script type="text/babel">
import REVERSAL from "@/constants/reversal.js";
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '../../EventBus.js';

export default {
  name: "ReversalConfirmation",
  data() {
    return {
      disableReversal: false,
      invoiceNums:[],
      spacesList:[]
    };
  },

  created() {
    const appliedPayments = this.payment?.is_inter_property_payment ? this.payment?.InterPropertyPayment.appliedPayments : this.payment?.AppliedPayments;
    this.REVERSALS = REVERSAL.REVERSALS;
    this.REFUND = REVERSAL.REFUND;
    this.invoiceNums = this.reversal_meta?.invoices?.map(item => "(Invoice: #"+ item.number +")");
    this.spacesList = appliedPayments?.map(item => "#"+ item.Invoice.unit_info.number);
  },

	methods: {
		...mapActions({
			reversePayment: 'paymentsStore/reversePayment'
		}),
		async processReversal() {
      this.disableReversal = true;

      if(
        this.reversalType !== this.REVERSALS.card.OFFLINE.value 
        && this.reversalType !== this.REFUND.value 
        && this.reversalType !== this.REVERSALS.credit.REVERSE.value
        && this.reversalType !== this.REVERSALS.eftpos.DIRECTREFUND.value
        && this.reversalType !== this.REVERSALS.directdebit.BANKREJECT.value
        && this.reversalType !== this.REVERSALS.card.CHARGEBACK.value ) {
        EventBus.$emit('selectAllInvoices');
      }

		  await this.reversePayment({ paymentId: this.payment.id, isRefund: this.isRefund, void_data: this.void_data, credit_data: this.credit_data, is_credit_invoice_line: this.is_credit_invoice_line});
      EventBus.$emit('reload_tenant_profile_leases');
      EventBus.$emit('disable_email_letter',true);
      this.$emit('close');
			this.dialog = false;
      this.disableReversal = false;
		},
    getUniqueItems (value, index, self){
      return self.indexOf(value) === index
    }
	},

  computed: {
		...mapGetters({
			reversal: 'paymentsStore/getReversal'
		}),
		reversalType() {
			return this.reversal.reversal_type;
		},
    title(){
      if( this.isRefund){
        return this.reversal.reversal_type == 'credit' ? 'Credit Reversal' : 'Payment Refund';
      }else{
      return this.reversal.reversal_type == 'credit' ? 'Credit Reversal' : 'Payment Reversal';
    }
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
		payment: {
      type: Object,
      required: true
    },
    reversal_meta: {
      type: Object,
      required: true
    },
    isRefund: {
      type: Boolean,
      required: false
    },
    void_data: {
      type: Object,
      required: false
    },
    credit_data: {
      type: Object,
      required: false
    },
    is_credit_invoice_line: {
      type: Boolean,
      required: false
    }
  },
};
</script>

<style scoped>
</style>