<template>
  <div>
    <hb-modal
      @close="$emit('close')"
      size="large"
      :title="title() + 'Payment'"
      v-model="dialog"
      :footerCancelOption="false"
      show-help-link
    >
      <template v-slot:content>
        <hb-form :label="title() + 'Payment Type'" required >
        <v-col cols="12" class="mt-0 mb-0 pb-1" >
          <hb-select
            v-model="reversal.type"
            :items="Object.values(getReversalOptions)"
            v-validate.disable="'required|max:45'"
            id="reversalType"
            name="reversalType"
            data-vv-scope="reversal"
            data-vv-as="Reverse Payment Type"
            :error="errors.has('reversal.reversalType')"
            :placeholder="'Select ' + title() + 'Payment Type'"
          ></hb-select>
         <!-- </v-col> -->

         <!-- <v-col cols="12"> -->
          <hb-checkbox
                v-if="reversal.type === 'directrefund' && paymentMethod === 'directdebit'"
                label="Same account as original direct debit account"
                v-model="dd_account"
                @change="getPaymentMethod"
              ></hb-checkbox>
         </v-col>

          <v-col cols="6" class="mt-0 mb-0" v-if="reversal.type === 'directrefund' && (paymentMethod === 'eftpos' || paymentMethod === 'cash' || paymentMethod === 'directdebit' || paymentMethod === 'check')" required full>
              <hb-text-field
                cols="6" 
                class="my-n1 pr-2"
                :readonly="dd_account"
                v-model="reversal.directrefund_account_name"
                v-validate.disable="'required|max:32'"
                data-vv-as="Account Name"
                data-vv-scope="reversal"
                :error="errors.has('reversal.directrefund_account_name')"
                placeholder="Account Name*"
                id="directrefund_account_name"
                name="directrefund_account_name"
                >
              </hb-text-field>
            </v-col>

            <v-col cols="6" class="mt-0 mb-0" v-if="reversal.type === 'directrefund' && (paymentMethod === 'eftpos' || paymentMethod === 'cash' || paymentMethod === 'directdebit' || paymentMethod === 'check') && isNZ !== null && !isNZ" required full>
              <hb-text-field
               :readonly="dd_account"
                cols="6" 
                class="my-n1 pr-2"
                v-if="reversal.type === 'directrefund' && (paymentMethod === 'eftpos' || paymentMethod === 'cash' || paymentMethod === 'directdebit' || paymentMethod === 'check') && isNZ !== null && !isNZ" required full
                v-model="reversal.directrefund_bsb_number"
                v-validate.disable="'required|numeric|length:6'"
                maxlength="6"
                data-vv-as="BSB Number"
                data-vv-scope="reversal"
                :error="errors.has('reversal.directrefund_bsb_number')"
                placeholder="BSB Number*"
                id="directrefund_bsb_number"
                name="directrefund_bsb_number"
                >
              </hb-text-field>
            </v-col>

            <v-col cols="6" class="mt-0 mb-0" v-if="reversal.type === 'directrefund' && (paymentMethod === 'eftpos' || paymentMethod === 'cash' || paymentMethod === 'directdebit' || paymentMethod === 'check')" required full>
              <hb-text-field
                cols="6" 
                class="my-n1 pr-2"
                v-if="reversal.type === 'directrefund' && (paymentMethod === 'eftpos' || paymentMethod === 'cash' || paymentMethod === 'directdebit' || paymentMethod === 'check')" required full
               :readonly="dd_account"
                v-model="reversal.directrefund_account_number"
                v-validate.disable="{ required: true, numeric: true, customAccountNumberValidation: true }"
                data-vv-as="Account Number"
                data-vv-scope="reversal"
                :error="errors.has('reversal.directrefund_account_number')"
                placeholder="Account Number*"
                id="directrefund_account_number"
                name="directrefund_account_number"
              >
              </hb-text-field>
            </v-col>

          <div class="mt-0 mb-0 pl-3 hb-text-light">
            <span v-if="(reversal.type === REVERSALS.card.CHARGEBACK.value && !is_fatzebra_payment)">
              Take this action to remove the payment line from the
              tenant’s ledger and issue a chargeback fee. You will also change
              the status of the invoice to unpaid.
            </span>
            <span v-if="reversal.type === REVERSALS.card.OFFLINE.value ">
              Take this action to remove the payment line item from the tenant’s ledger. You will also change the status of the invoice to unpaid.
            </span>
            <span v-if="reversal.type === REVERSALS.eftpos.CHARGEBACK.value && is_fatzebra_payment">
              Take this action to remove the payment line item from the tenant’s ledger and issue a Chargeback Fee. This will not return the money to the tenant’s original credit card.  Hummingbird will process any applicable delinquency stages in the next 10 minutes.
            </span>
            <span v-if="reversal.type === REVERSALS.directdebit.BANKREJECT.value">
              Take this action to remove the payment line item from the tenant’s ledger and issue a Direct Debit reject fee. This will not credit the tenant’s original payment method. The action will change the status of the tenant’s invoice to unpaid. Hummingbird will process any applicable delinquency stages in the next 10 minutes.
            </span>
            <span v-if="reversal.type === REVERSALS.check.NSF.value">
              Take this action to remove the payment line from the tenant’s
              ledger and issue them an NSF fee. You will also change the status
              of the invoice to unpaid.
            </span>
            <span v-if="reversal.type === REVERSALS.ach.ACH.value">
              Take this action to remove the payment line item from the
              tenant’s ledger and issue an ACH reversal fee. This action does
              not credit the tenant’s credit card. This action will change the
              status of the tenant’s invoice to unpaid.
            </span>
            <span v-if="reversal.type === 'void'">
              Take this action to remove the payment line from the tenant’s ledger. You will also change the status of the invoice to unpaid.
            </span>
          </div>
        </hb-form>
        
        <hb-form :label="title(true) + 'Amount'" v-if="showRefund" required >
          <v-col v-if="is_inter_property_payment">
            <refund
              v-if="showRefund"
              :payments="payments"
              :payment="payment"
            />
          </v-col>
          <v-col v-else class="mt-0 mb-0" >

            <v-row no-gutters>
              <span v-if="!isVoid" class="mt-3 mb-1 hb-text-light">Available Payment for  {{title()}}:  <b>{{ totalInvoiceAmount | formatMoney }}</b></span>
              <span v-else class="mt-3 mb-1 hb-text-bold">Total Amount to {{title()}}:  <b> {{ totalInvoiceAmount | formatMoney }}</b></span>
              </v-row>
              <v-row no-gutters>
        <div v-if="!isVoid && !(invoice_void_save)" class="hb-inline-sentence-text">
          Enter the amount to {{title()}} : 
        </div>
        
        <hb-text-field
            condensed
            box
            medium
            type="number"
            class="amount-paying"
            v-if="!isVoid && !(invoice_void_save)"
            v-model="reversal.amount"
            v-validate="`required|max_value:${totalInvoiceAmount}`"
            required
             prepend-inner-icon="mdi-currency-usd"
            data-vv-scope="reversal"
            data-vv-as="Amount"
            id="amount"
            name="amount"
            :error="errors.has('reversal.amount')"
            :class="{'error-border': errors.has('reversal.amount')}" >
        </hb-text-field>
            </v-row>

            <hb-notification
            class="mt-0 mb-0" 
            v-if="reversal.type === 'refund' && paymentMethod === 'eftpos'" required full
              title=""
              type="info"
              not-dismissable
            >EFTPOS refund total must be less then $ 100. Otherwise, Direct Refund will be used.</hb-notification>
            <hb-notification
            class="mt-0 mb-0" 
            v-if="reversal.warning" required full
              title=""
              @close="reversal.warning=false"
              type="caution"
            > Refund amount crosses $100- Direct Refund will be chosen by default.</hb-notification>
            <hb-notification
            class="mt-0 mb-0" 
            v-if=" total_eftpos_amount > 300" required full
              title=""
              @close="total_eftpos_amount=false"
              type="caution"
            >  Daily EFTPOS Refund Limit is crossing $300- Direct Refund will be selected by default.</hb-notification>
          </v-col>
         
        </hb-form>

        <hb-form label="Refund Transaction ID" 
          v-if="reversal.type === 'refund' && paymentMethod === 'eftpos'">
          <v-col class="mt-0 mb-0" >
            <hb-textarea
            v-model="reversal.refund_trans_id"
            v-validate.disable="'max:1000'"
            id="refund_trans_id"
            name="refund_trans_id"
            data-vv-scope="reversal"
            data-vv-as="Refund Transaction ID"
            :error="errors.has('reversal.refund_trans_id')"
            placeholder="Enter Refund Transaction ID"
          ></hb-textarea>
          </v-col>
        </hb-form>

        <hb-form :label="'Reason for ' + title(true)" required>
          <v-col class="mt-0 mb-0" >
            <hb-textarea
            v-model="reversal.reason"
            v-validate.disable="'required|max:1000'"
            id="reason"
            name="reason"
            data-vv-scope="reversal"
            data-vv-as="Reason for Reversal"
            :error="errors.has('reversal.reason')"
            :placeholder="'Enter Reason for ' + title(true)"
          ></hb-textarea>
          </v-col>
        </hb-form>
      </template>
      <template v-slot:actions>
        <hb-btn v-if="paymentMethod !== 'eftpos'" color="primary" @click="invoice_void_save ? saveDirectRefundDetails(): reversePayment()"> {{ invoice_void_save ? 'Confirm & Continue' : title()}} {{ paymentMethod == 'credit' ? "Credit" : (invoice_void_save ? '': "Payment")}}</hb-btn>
        <hb-btn v-else-if="paymentMethod === 'eftpos' && reversal.type === 'refund'" color="primary" @click="reversePayment">{{title()}} {{ paymentMethod == 'credit' ? "Credit" : "Payment"}}</hb-btn>
        <hb-btn v-else-if="paymentMethod === 'eftpos' && reversal.type === 'void'" color="primary" @click="voidPayment">Void {{ paymentMethod == 'credit' ? "Credit" : "Payment"}}</hb-btn>
        <hb-btn v-else color="primary" @click="reversePayment">{{title()}} {{ paymentMethod == 'credit' ? "Credit" : "Payment"}}</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import REVERSAL from "@/constants/reversal.js";
import { mapMutations, mapGetters } from "vuex";
import { EventBus } from "../../EventBus.js";
import api from '../../assets/api.js';
import Refund from "./Refund.vue";
import { notificationMixin } from  '../../mixins/notificationMixin.js';

export default {
  name: "Reversal",
  mixins: [notificationMixin],
  data() {
    return {
      total_eftpos_amount:"",
      dd_account: false,
      reversal: {
        amount:"",
        warning:"",
        type: "",
        reason: "",
        refund_trans_id: "",
        directrefund_account_number: "",
        directrefund_bsb_number: "",
        directrefund_account_name: ""
      },
      showRefund: false,
      isAUS: false,
      isNZ: false,
      invoice_void_save: false,
      total_refund_amount: 0,
    };
  },

  async created() {
    this.REVERSALS = REVERSAL.REVERSALS;
    this.REFUND = REVERSAL.REFUND;
    this.$validator.reset('reversal');
    EventBus.$on("closeReversalModal", this.closeReversalModal);
    EventBus.$on('invoice_void_save', this.handleInvoiceVoidSave);
    if((this.paymentMethod.toLowerCase() === 'cash' || this.paymentMethod.toLowerCase() === 'check') && 
      this.is_fatzebra_payment && this.isRefund) {
      this.reversal.type = 'directrefund';
    }else if((this.paymentMethod.toLowerCase() === 'cash' || this.paymentMethod.toLowerCase() === 'check') && 
      this.is_fatzebra_payment && !this.isRefund){
      this.reversal.type = 'offline'
    }else if((this.paymentMethod.toLowerCase() === 'directdebit') && 
      this.is_fatzebra_payment && this.isRefund){
      this.reversal.type = 'directrefund'
    }
    
    await this.isAUSOrNZ();
  },
  mounted() {
          this.$validator.extend('customAccountNumberValidation', {
          validate: value => {
            if (this.isAUS) {
            const regex = /^[0-9]{4,9}$/;
            return regex.test(value);
          } else if (this.isNZ) {
            const regex = /^[0-9]{14,16}$/;
            return regex.test(value);
          }
          return false;
        },
          getMessage: field => {
            if (this.isAUS) {
              return `The ${field} must be a valid Australian account number (4-9 digits).`;
            } else if (this.isNZ) {
              return `The ${field} must be a valid New Zealand account number (14-16 digits).`;
            }
            return `Please contact the onboarding team to get the address updated.`
          }
        })
      },

  methods: {
    ...mapMutations({
      setReversal: "paymentsStore/setReversal",
      resetReversal: "paymentsStore/resetReversal",
      setReversalObject: 'paymentsStore/setReversalObject'
    }),

    closeReversalModal() {
      this.$validator.reset('reversal');
      this.dialog = false;
      EventBus.$emit("resetValidation");
    },

    async reversePayment() {
      const invoices_to_reverse = this.reversal_meta?.invoices || [];
      const methods_with_partial_payments = this.getMethodsWithPartialPayments();
      let reversal_type = this.reversal?.type.toUpperCase() || "";
      if(this.reversal?.type === 'credit') reversal_type = 'REVERSE';
    
      let totalAmount = 0;
      invoices_to_reverse.forEach(invoices_to_reverse => {
        totalAmount += invoices_to_reverse.amount;
      });

      if(this.reversal.type === 'refund' && this.paymentMethod === 'eftpos' && this.reversal.amount >= 100){
        this.reversal.type = "directrefund"
         this.reversal.warning = true
        return;
      }

      if(this.reversal.type === 'refund' && this.paymentMethod === 'eftpos' && this.reversal.amount + this.total_eftpos_amount > 300){
        this.reversal.type = "directrefund"
        this.total_eftpos_amount = this.reversal.amount + this.total_eftpos_amount 
        return;
      }


      // if( reversal_type != '' && methods_with_partial_payments.indexOf(reversal_type) != -1 && invoices_to_reverse?.length == 0){
      //   this.showMessageNotification({ type: 'error', description: 'Please select the invoice(s) to refund.'});
      //   return;
      // }

      const valid = await this.$validator.validateAll('reversal');
      if (!valid) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      }

      for(let i = 0; i < invoices_to_reverse.length; i++) {
        if( invoices_to_reverse[i].amount > invoices_to_reverse[i].invoice_amount ){
          this.showMessageNotification({ type: 'error', description: 'You are trying to refund more than the payment applied.'});
          return;
        }
      }

      this.setReversal({
        property: "reversal_type",
        propertyValue: this.reversal.type,
      });

      this.setReversal({
        property: "reason",
        propertyValue: this.reversal.reason,
      });
      if(!this.is_inter_property_payment){
        this.setReversal({
        property: "amount",
        propertyValue: this.reversal.amount,
        });
      }
      this.setReversal({
        property: "refund_trans_id",
        propertyValue: this.reversal.refund_trans_id,
      });
      if(this.reversal.type === 'directrefund'){
      this.setReversal({
        property: "account_name",
        propertyValue: this.reversal.directrefund_account_name,
      });
      this.setReversal({
        property: "bsb_number",
        propertyValue: this.reversal.directrefund_bsb_number,
      });
      this.setReversal({
        property: "account_number",
        propertyValue: this.reversal.directrefund_account_number,
      });
      this.setReversal({
        property: "payment_method",
        propertyValue: this.paymentMethod
      });
    }

      this.dialog = false;

      let reversalOptions = this.REVERSALS[this.paymentMethod];
      reversalOptions =  Object.values(reversalOptions)
        .find(x => x.value === this.reversal.type);

      const permission = reversalOptions?.permission

      if(permission && !this.hasPermission(permission)){
        this.showMessageNotification({ description: 'You do not have permission to perform this action. Please contact your administrator.'});
      } else {
        EventBus.$emit("show_reversal_confirmation");
      }
    },

    async voidPayment(){
      console.log('Inside void');
      const valid = await this.$validator.validateAll('reversal');
      if (!valid) {
        return;
      }
      this.dialog = false;
      let reversalOptions = this.REVERSALS[this.paymentMethod];
      reversalOptions =  Object.values(reversalOptions)
        .find(x => x.value === this.reversal.type);

      const permission = reversalOptions?.permission

      if(permission && !this.hasPermission(permission)){
        this.showMessageNotification({ description: 'You do not have permission to perform this action. Please contact your administrator.'});
      } else {
        EventBus.$emit("show_void_confirmation");
      }
    },

    async handleInvoiceVoidSave(refundAmountForCredit) {
      this.invoice_void_save = true;
      await this.resetDirectRefund();
      this.total_refund_amount = refundAmountForCredit;
      this.reversal.amount = this.refundAmount - this.total_refund_amount;
    },

    async resetDirectRefund() {
        this.reversal.reason = '',
        this.reversal.directrefund_account_name = '',
        this.reversal.directrefund_bsb_number = '',
        this.reversal.directrefund_account_number = ''
        this.$validator.reset('reversal');
    },

    async saveDirectRefundDetails() {
      const invoices_to_reverse = this.reversal_meta?.invoices || [];

      let totalAmount = 0;
      invoices_to_reverse.forEach(invoices_to_reverse => {
        totalAmount += invoices_to_reverse.amount;
      });
      console.log('invoices_to reverse: ', invoices_to_reverse)

      const valid = await this.$validator.validateAll('reversal');
      if (!valid) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      }
      this.errors.clear('reversal');

      for(let i = 0; i < invoices_to_reverse.length; i++) {
        if( invoices_to_reverse[i].amount > invoices_to_reverse[i].invoice_amount ){
          this.showMessageNotification({ type: 'error', description: 'You are trying to refund more than the payment applied.'});
          return;
        }
      }

      let reversalOptions = this.REVERSALS[this.paymentMethod];
      reversalOptions =  Object.values(reversalOptions)
        .find(x => x.value === this.reversal.type);

      const permission = reversalOptions?.permission
      console.log('permission: ', permission);
      if(permission && !this.hasPermission(permission)){
        this.showMessageNotification({ description: 'You do not have permission to perform this action. Please contact your administrator.'});
      } else {
        setTimeout(() => {
          this.resetReversal();
          console.log("Reversal reset!", this.reversal);
        }, 0);
        EventBus.$emit('Hb-Payments:direct_refund');
        this.setReversal({
        property: "reversal_type",
        propertyValue: this.reversal.type,
      });

      this.setReversal({
        property: "reason",
        propertyValue: this.reversal.reason,
      });
      if(this.is_inter_property_payment){
        this.setReversal({
        property: "amount",
        propertyValue: 0,
        });
      }else{
        this.setReversal({
        property: "amount",
        propertyValue: this.reversal.amount,
        });
      }
      this.setReversal({
        property: "account_name",
        propertyValue: this.reversal.directrefund_account_name,
      });
      this.setReversal({
        property: "bsb_number",
        propertyValue: this.reversal.directrefund_bsb_number,
      });
      this.setReversal({
        property: "account_number",
        propertyValue: this.reversal.directrefund_account_number,
      });
      this.setReversal({
        property: "payment_method",
        propertyValue: this.paymentMethod
      });

        this.dialog = false;
      }

    },

    getPartialAllowedReversals() {
      let reversalOptions = this.REVERSALS[this.paymentMethod]; 
      const partialPaymentAllowedReversals = this.getMethodsWithPartialPayments();
      let filteredReversalOptions = {};
      for (const r of partialPaymentAllowedReversals) {
        filteredReversalOptions[r] = reversalOptions[r];
      }
      reversalOptions = filteredReversalOptions;
      return reversalOptions;
    },
    getMethodsWithPartialPayments(){
      let reversalOptions = this.REVERSALS[this.paymentMethod];      
      const methods = Object.keys(reversalOptions).filter(r => r === 'OFFLINE' || r === 'REVERSE' || r === 'REFUND' || r === 'CHARGEBACK' || r === 'DIRECTREFUND' || r === 'BANKREJECT');
      return methods;
    },
    getFatzebraReversalOptions(reversalOptions, method){
      if(method == "cash"){
        return Object.values(reversalOptions).filter(item => item.value !== "refund");
      }else if(method == "directdebit"){
        return Object.values(reversalOptions).filter(item => item.value !== "void");
      }
      return reversalOptions
    },
    getEftposReversalOptions(reversalOptions, method, isRefund){
      if(!isRefund) {
        return Object.values(reversalOptions).filter(item => item.value !== "refund" && item.value !== "directrefund");
      } else {
        return Object.values(reversalOptions).filter(item => item.value !== "chargeback" && item.value !== "offline");
      }
    },
    getDirectDebitRefundOptions(reversalOptions, isRefund){
      if(!isRefund) {
        return Object.values(reversalOptions).filter(item => item.value !== "refund" && item.value !== "directrefund");
      } else {
        return Object.values(reversalOptions).filter(item => item.value !== "chargeback" && item.value !== "offline" && item.value !== "bankreject");
      }
    },
    getCashReversalOptions(reversalOptions, method, isRefund){
      if(!isRefund) {
        return Object.values(reversalOptions).filter(item => item.value !== "refund" && item.value !== "directrefund" && item.value !== "void");
      } else {
        return Object.values(reversalOptions).filter(item => item.value !== "chargeback" && item.value !== "offline" && item.value !== "void" && item.value !== "refund");
      }
  },
  getPaymentMethod() {
   
      if(this.dd_account) {
        api.get(this, api.PAYMENTS +this.payment.id + '/getDDPaymentMethod' ).then(r => {
          this.reversal.directrefund_account_name = r[0].name_on_card 
          if(this.isNZ) {
            this.reversal.directrefund_account_number = r[0].routing_number.replace(/-/g, "") + r[0].card_end 
          } else {
            this.reversal.directrefund_bsb_number = r[0].routing_number.replace(/-/g, "");    
            this.reversal.directrefund_account_number = r[0].card_end                   
          }
                });
      }else{
        this.reversal.directrefund_account_name = ''
          this.reversal.directrefund_bsb_number = ''    
          this.reversal.directrefund_account_number = ''   
      }
    },
    getCashRefundOptions(reversalOptions, isRefund) {
      if(isRefund) {
        return Object.values(reversalOptions).filter(item => item.value !== "void" && item.value !== 'refund');
      } else {
        return Object.values(reversalOptions);
      }
    },
    getCardReversalOptions(reversalOptions, isRefund) {
      if(!isRefund) {
        return Object.values(reversalOptions).filter(item => item.value !== "refund");
      } else {
        return Object.values(reversalOptions).filter(item => item.value !== "chargeback" && item.value !== "offline");
      }
    },
    getCheckReversalOptions(reversalOptions, isRefund) {
      if(isRefund) {
        return Object.values(reversalOptions).filter(item => item.value !== "refund" && item.value !== "nsf" && item.value !== "void" 
          && item.value !== "chargeback" && item.value !== "offline"
        );
      } else {
        return Object.values(reversalOptions).filter(item => item.value !== "refund" && item.value !== "nsf" && item.value !== "void"
          && item.value !== "directrefund");
      }
    },
    getUSRefundReversalOptions(reversalOptions, paymentMethod, isRefund) {
          if(paymentMethod.toLowerCase() === 'cash' || paymentMethod.toLowerCase() === 'check') {            
            return Object.values(reversalOptions).filter(item => item.value !== "directrefund" && item.value !== "offline");
        }
      },
  getEftposData(){
    api.get(this, api.PAYMENTS +this.payment.id + '/getEftposPayments' ).then(r => {
      this.total_eftpos_amount = r[0].sum_amount                
                });
  },
  async isAUSOrNZ() {
          let r = await api.get(this, api.PROPERTIES + this.property_id);
          this.properties = r.property;
          if(this.properties.Address.country  && /^(AUS|AU|Australia)$/i.test(this.properties.Address.country)) {
            this.isAUS = true;
            this.isNZ = false;
          } else if(this.properties.Address.country && /^(NZ|NZL|New Zealand)$/i.test(this.properties.Address.country)) {
            this.isNZ = true;
            this.isAUS = false;
          } else {
            this.isAUS = false;
            this.isNZ = false;
          }
          console.log('is AUS or NZ ?', this.isAUS, this.isNZ);   
        },
  title(isReversal = false){
    if(this.is_fatzebra_payment && this.isRefund){
      return  'Refund '
    }
      return this.paymentMethod == 'credit' ? 'Reverse Credit' : (isReversal ? 'Reversal ' : 'Reverse ');
    },
  },

  computed: {
    ...mapGetters({
			reversal_meta: 'paymentsStore/getReversal'
		}),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    getReversalOptions() {
      let reversalOptions = this.REVERSALS[this.paymentMethod];
      
      if(this.isOnlyNSF)
        reversalOptions = {"NSF" : this.REVERSALS[this.paymentMethod]['NSF']};

      if(this.isPartialRefund) {
        reversalOptions = this.getPartialAllowedReversals();
      }

      if(this.is_fatzebra_payment){
        if(this.paymentMethod.toLowerCase() === 'eftpos') {          
          reversalOptions = this.getEftposReversalOptions(reversalOptions, this.paymentMethod, this.isRefund);          
        }else if (this.paymentMethod.toLowerCase() === 'directdebit') {
          reversalOptions = this.getDirectDebitRefundOptions(reversalOptions, this.isRefund);
        }else if (this.paymentMethod.toLowerCase() === 'cash') {
          reversalOptions = this.getCashReversalOptions(reversalOptions, this.paymentMethod, this.isRefund);
        }else if(this.paymentMethod.toLowerCase() === 'card') {
          reversalOptions = this.getCardReversalOptions(reversalOptions, this.isRefund);
        } else if(this.paymentMethod.toLowerCase() === 'check') {
          reversalOptions = this.getCheckReversalOptions(reversalOptions, this.isRefund);
        }
        else {
          reversalOptions = this.getFatzebraReversalOptions(reversalOptions, this.paymentMethod)
        }
      } else if(this.paymentMethod.toLowerCase() === 'check' || this.paymentMethod.toLowerCase() === 'cash') {
        reversalOptions = this.getUSRefundReversalOptions(reversalOptions, this.paymentMethod, this.isRefund);
      }
    

      return reversalOptions;
    },
    totalInvoiceAmount() {
      if (this.is_credit_invoice_line || this.invoice_void_save) {
        return this.refundAmount - this.total_refund_amount || 0;
      }
      if(this.isVoid==true){
        this.reversal.amount = this.payments.reduce((sum, payment) => sum + payment.amount, 0);
      }
      if (this.payment.DirectRefunds && this.payment.DirectRefunds.length > 0) {
      return this.payments.reduce((sum, payment) => sum + payment.amount, 0) - this.payment.DirectRefunds.filter(record => record.status !== "processed" && record.status !== "void")
    .reduce((sum, record) => sum + parseFloat(record.amount), 0);
    }else{
      return this.payments.reduce((sum, payment) => sum + payment.amount, 0)
    }
  }

  },

  components: {
    Refund,
  },

  destroyed(){
    EventBus.$off("closeReversalModal", this.closeReversalModal);
    EventBus.$off('invoice_void_save', this.handleInvoiceVoidSave);
  },

  watch: {
    "reversal.type"(val) {
      if(val === this.REFUND.value || val === this.REVERSALS.card.OFFLINE.value || val === this.REVERSALS.credit.REVERSE.value || val === this.REVERSALS.eftpos.DIRECTREFUND.value || val === this.REVERSALS.cash.DIRECTREFUND.value || val === this.REVERSALS.directdebit.DIRECTREFUND.value || val === this.REVERSALS.card.CHARGEBACK.value ||  val === this.REVERSALS.directdebit.BANKREJECT.value) {
        this.showRefund = true;
      } else {
        this.showRefund = false;
      }
      if( this.reversal.type == "refund" && this.paymentMethod == "eftpos"){
        this.getEftposData()
      }
    },
    "reversal.amount"() {
			    EventBus.$emit('setInvoices', this.payments);
		},
    dialog(newVal) {
      if (!newVal) {
        this.errors.clear("reversal");
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    paymentMethod: {
      type: String,
      required: true,
    },
    payments: {
      type: Array,
      default: [],
    },
    is_fatzebra_payment: {
      type: Boolean,
      required: false
    },
    is_inter_property_payment: {
      type: Boolean,
      required: false
    },
    refundOption: {
      type: Object,
      default: {},
    },
    payment: {
      type: Object,
      required: true,
    },
    isPartialRefund: {
      type: Boolean,
      required: false
    },
    isOnlyNSF: {
      type: Boolean,
      required: false
    },
    isRefund: {
      type: Boolean,
      required: false
    },
    property_id: {
      type: String,
      required: false
    },
    isVoid: {
      type: Boolean,
      required: false
    },
    refundAmount: {
      type: Number,
      required: false
    },
    is_credit_invoice_line: {
      type: Boolean,
      required: false
    }
  },
};
</script>

<style>
.refund-reversal .hb-aviary-form-padding-content {
	padding-right: 12px !important;
}
.error-border {
    border-color: red;
}
.amount-paying input[type="number"]::-webkit-outer-spin-button,
.amount-paying input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
